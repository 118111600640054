@font-face {
  font-family: Merriweather-Light;
  font-weight: 300;
  src: url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;500;700&family=Merriweather:wght@300&display=swap');
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 300;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-Light.ttf");
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 400;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-Regular.ttf");
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 500;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-Medium.ttf");
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 600;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-SemiBold.ttf");
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 700;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-Bold.ttf");
}

@font-face {
  font-family: MerriweatherSans;
  font-weight: 800;
  src: url("./_metronic/materialUIThemeProvider/fonts/MerriweatherSans/MerriweatherSans-ExtraBold.ttf");
}


* {
  // font-family: "Open Sans", Helvetica, sans-serif;
  font-family: "MerriweatherSans";
}

body {
  background: #fff !important;
}

// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~react-image-gallery/styles/css/image-gallery.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout Skins
@import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
@import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
@import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
@import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// for images gallery (in product view page)

.image-gallery-thumbnails {
  padding: 0;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.image-gallery-thumbnail {
  border-radius: 0;
  width: 100px !important;
  height: 100px !important;
  border: 0px solid transparent;
  margin-right: 15px;


  .image-gallery-thumbnail-inner {
    display: block;
    position: relative;
  }

  .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
  }
}

.image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image-gallery-thumbnail-inner {
  width: 100px !important;
  height: 100px !important;
}

.image-gallery-thumbnail-inner img {
  object-fit: contain;
  width: 100px !important;
  height: 100px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: none;
}

.image-gallery-slide-wrapper {
  position: relative;

  &.left,
  &.right {
    display: inline-block;
    // width: auto !important;
    // width: calc(100% - 200px); // 100px + 10px for margin
    height: 100%;
    min-height: 150px;
    max-height: 650px;
    max-width: 220px;
    width: 100%;
    // min-width: 350px;

    @media (max-width: $ig-small-screen) {
      height: 100%;
      // width: calc(100% - 87px); // 81px + 6px for margin
      min-height: 150px;
      max-height: 650px;
      max-width: 200px;
      width: 100%;
      // min-width: 350px;
    }

    @media screen and (max-width: 1330px) {
      max-width: 180px;
    }

    @media screen and (max-width: 1000px) {
      max-width: 150px;
    }
  }

  &.image-gallery-rtl {
    direction: rtl;
  }
}

.image-gallery-svg {
  color: #216214 !important;
}

.image-gallery-thumbnails-container {
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.MuiTablePagination-root {
  border: none !important;
}

.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }

  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }

  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 100px;

    @media (max-width: $ig-small-screen) {
      width: 81px; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        +.image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 6px;
        }
      }

    }
  }

  &.left,
  &.right {
    margin: 0 25px;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}

// fix material ui icons in safari
.MuiSvgIcon-root {
  transform: scale(1);
}

// Header Skins
.kt-header-base-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/light.scss';
}

.kt-header-base-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/base/dark.scss';
}

// Header Menu Skins
.kt-header-menu-light {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/light.scss';
}

.kt-header-menu-dark {
  @import './_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss';
}

// Brand Skins
.kt-brand-dark {
  @import './_metronic/_assets/sass/global/layout/brand/skins/dark.scss';
}

.kt-brand-light {
  @import './_metronic/_assets/sass/global/layout/brand/skins/light.scss';
}

// Aside skins
.kt-aside-dark {
  @import './_metronic/_assets/sass/global/layout/aside/skins/dark.scss';
}

.kt-aside-light {
  @import './_metronic/_assets/sass/global/layout/aside/skins/light.scss';
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

@media screen and (max-width: 500px) {
  .MuiTablePagination-root {
    width: 100%;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiTablePagination-spacer,
  .MuiTablePagination-spacer+.MuiTablePagination-caption {
    display: none;
  }

  .MuiTablePagination-select {
    padding-left: 0 !important;
  }

  .MuiTablePagination-toolbar,
  .MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiTablePagination-selectRoot {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}

// CkEditor
.ck-editor__editable {
  min-height: 400px;
}

//Redesign

.kt-menu-cart-icon {
  color: #441861 !important;
}

.MuiDialog-scrollPaper {
  background-color: rgba(0, 0, 0, 0.76) !important;
}
.MuiDialogContent-root:first-child {
  padding: 0 !important;
}

.MuiDialogActions-root {
  padding: 0 !important;
}
.MuiDialogContentText-root {
  margin-bottom: 35px !important;
}
.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text {
  color: #441861 !important;

}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
  color: #000000 !important;
}

.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item:hover>.kt-menu__link .kt-menu__link-text {
  color: #441861 !important;
font-weight: 600 !important;}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text,
.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text {
  color: #441861 !important;

}

// .gm-style {
//   z-index: 99 !important;
// }

.kt-menu__link {

  .kt-menu__link-text {
    color: #441861 !important;
    font-size: 17px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.gm-ui-hover-effect {
  display: none !important;
}

.MuiTableCell-body {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.kt-menu__item--isAdmin {
  > a {
    background-color: transparent !important;
  }
  > a:hover {
    background-color: transparent !important;
  }
}

.cluster > div {
  transform: scaleX(1.4) !important;
  font-weight: 300 !important;
  display: inline-block !important;
}

label {
  margin-bottom: 0 !important;
}


// Slider
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  padding: 0 !important;

  display: block;
  margin-left: 0;
  margin-right: 0;
}

.slick-center {
  transform: scale(1.1)
}

.slick-slide {
  transition: 0.5s all ease;
  opacity: 0.5;
}

.slick-active {
  padding: 0;
  opacity: 1;
}

// .slick-slide:not(.slick-active) {
//   margin: 20px 0;
// }

 .custom-dots {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 0;
   padding: 0;
 }

.slick-dots {
  bottom: 15px !important;
  @media screen and (max-width: 500px) {
    bottom: 30px !important;
  }

  &>li {
    width: 15px !important;
    height: 16px !important;
    margin: 0 7.5px;

    &>button {
      width: 15px !important;
      height: 16px !important;
    }
    &>button::before {
      width: 15px !important;
      height: 16px !important;
      opacity: 0.5;
      font-size: 0;
      background-color: #fff;
    }
  }

  &>li.slick-active {
    width: 15px !important;
    height: 16px !important;
    &>button::before {
      opacity: 1;
      background-color:#fff;
    }
  }
}

.custom-dot {
  width: 15px !important;
  height: 16px !important;
  border-radius: 12px;
  border: none;
  background-color: red;
  margin-right: 30px;
  //margin-top: -35px;
  cursor: pointer;
}


.custom-dot-active {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
  background-color: blue;
  margin-right: 30px;
  //margin-top: -35px;
  cursor: pointer;
}

.iconContent {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
}

.MuiDialog-paper {
  padding: 32px;
  position: relative;
  overflow-y: auto;
}
