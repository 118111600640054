// React root container
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

// Global link cursor
a {
  cursor: pointer;
}

// Auth form
.MuiInput-underline:focus:not(.Mui-focused):before,
.MuiInput-underline:active:not(.Mui-focused):before,
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #441861 !important;
}

.MuiCardContent-root {
    .Mui-selected {
      background-color: rgb(33 98 20 / 15%) !important; /* Выбранный цвет фона */
      border-radius: 5px;
    }

    .Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
      background-color: transparent !important; /* Выбранный цвет фона */
    }
}

.MuiTab-root {
  font-size: 18px !important;
  font-weight: bold !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(33, 98, 20, 0.5) !important; /* Желаемый цвет границы */
}

// builder
.builder-tabs {
  .MuiTabs-flexContainer {
    button {
      margin-right: 20px;
      padding: 18px 0;
      min-width: auto;
      text-transform: none;
      font-size: 1rem;
    }
  }

  .PrivateTabIndicator-colorSecondary-64 {
    background-color: #441861;
  }
}

.MuiFormControl-marginNormal {
  margin-top: 20px;
  width: 100%;
}

.MuiSwitch-colorSecondary.Mui-checked {
  //color: #441861 !important;

  &:hover {
    background-color: rgba(88, 103, 221, 0.08) !important;
  }

  //&+.MuiSwitch-track {
  //  background-color: #441861 !important;
  //}
}

// Partials
// notice
.notice__paragraph {
  font-weight: 400;
  font-size: 17px;
}

// animated-loading
.header-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}


// quick-actions-panel
.MuiBackdrop-root {
  background: rgba(0, 0, 0, .05) !important;
}
.MuiButton-root {
  border-radius: 0 !important;
}

// Demos examples toolbar
.kt-comming-soon {
  .kt-demo-panel__item-preview-overlay {
    opacity: 1 !important;
  }
}

// user-notifications
.tab-content {
  background-color: #fff;
}

// Material UI
// For Select component
.react-select {
  .MuiInputBase-input {
    display: flex;

    .css-1okebmr-indicatorSeparator {
      margin: 0;
    }

    &>div:first-child {
      flex: 1;

      .MuiTypography-colorTextSecondary {
        left: 2px;
        bottom: 6px;
        position: absolute;
        font-size: 16px;
      }

      input {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1.5;
        font-size: 16px;
      }
    }
  }

  .MuiInputBase-root {
    font-size: 1.4rem;
  }
}

.select_input-h {
  .MuiSelect-select {
    height: 41px;
    box-sizing: border-box;
  }
}

.select__placeholder {
  font-weight: 400;
}

.MuiPaper-elevation1 {
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, .01) !important;
}

.MuiButton-label {
  font-size: 13px;
}

.MuiInputBase-input {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

// .MuiOutlinedInput-input:not(.MuiOutlinedInput-inputAdornedStart):not(.MuiSelect-selectMenu) {
//   padding: 16px 15px 16px !important;
// }

.MuiSelect-selectMenu:not(.MuiInputBase-input) {
  height: 55px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 30px 0 15px !important;
}

.MuiSelect-selectMenu {
  // text-overflow: unset !important;
}

.MuiOutlinedInput-root {
  height: 100% !important;
}