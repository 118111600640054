//
// Header Mobile
//




// Desktop mode
@include kt-desktop {
	.kt-header-mobile {
		display: none;
	}
	.kt-header-mobile-search {
		display: none;
	}
}

// Tablet & mobile mode
@include kt-tablet-and-mobile {
    .kt-header-mobile-search {
      position: fixed;
      padding: 0 20px;
      height: kt-get($kt-header-config, base, mobile, search, height);
      width:100%;
      top: kt-get($kt-header-config, base, mobile, self, default, height);
      background: #fff;
      z-index: 10;
    }
	.kt-header-mobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 kt-get($kt-page-padding, mobile);
		height: 66px;
		// height: kt-get($kt-header-config, base, mobile, self, default, height);
		min-height: kt-get($kt-header-config, base, mobile, self, default, height);
		position: relative;
		background: #fff;
		z-index: 1;

		// Fixed
		.kt-header-mobile--fixed & {
			position: fixed;
			background: #fff;
			top: 0;
			right: 0;
			left: 0;
			z-index: kt-get($kt-header-config, base, mobile, self, fixed, zindex);
			// z-index:11;
		}

		// Logo
		.kt-header-mobile__logo {
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		// Toolbar
		.kt-header-mobile__toolbar {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			flex: 1 1 auto;

			@include kt-burger-icon-build(kt-header-mobile__toggler, kt-get($kt-header-config, base, mobile, toolbar, toggler));
			@include kt-burger-icon-skin(kt-header-mobile__toggler, kt-get($kt-header-config, base, mobile, toolbar, toggler, color));

			.kt-header-mobile__toggler {
				margin: 0 1.5rem 0 0;
			}

			.kt-header-mobile__topbar-toggler {
				@include kt-button-reset();
				display: flex;
				align-items: center;
				margin: 0 0.5rem 0 1rem;

				i {
					@include kt-transition();
					color: kt-get($kt-header-config, base, mobile, toolbar, toggler, color, default);
					font-size: 1.5rem;
					line-height: 0;
				}

				&.kt-header-mobile__topbar-toggler--active,
				&:hover {
					i {
						@include kt-transition();
						color: kt-get($kt-header-config, base, mobile, toolbar, toggler, color, active);
					}
				}
			}
		}
	}

	.kt-header-logo {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 kt-get($kt-page-padding, desktop);
		display: flex;
		align-items: center;
		//margin-left: 25px;

		@include kt-mobile-sm {
			position: relative;
			left: 0;
			padding:0;
			transform: none;
		}
	}

	.kt-subheader--fixed {
		padding-top: 15px;
	}
}
