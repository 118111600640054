.image-gallery {
  height: 100%;
}

.image-gallery.one-image > .image-gallery-content {
  display: flex;
  width: 100%;
}

.image-gallery-content {
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  height: 100%;
  position: relative;
  display: grid;
  min-height: 250px;
  max-height: auto;
  gap: 28px;
  width: 100%;
  grid-template-columns: 104px minmax(200px, 480px);
}

@media (max-width: 1280px) {
  .image-gallery-content {
    gap: 22px;
    grid-template-columns: 82px minmax(200px, 480px);
  }
}

@media (max-width: 1199px) {
  .image-gallery-content {
    min-height: auto;
  }
}

@media (max-width: 1023px) {
  .image-gallery-content {
    grid-template-columns: 104px 1fr;
    height: auto;
    width: 100%;
  }
}

@media (max-width: 767px) {
  .image-gallery-content {
    grid-template-columns: 54px 1fr;
    height: auto;
    width: 100%;
    /* display:flex */
  }
  .image-gallery-thumbnails-wrapper .image-gallery-thumbnail,
  .image-gallery-thumbnails-wrapper .image-gallery-thumbnail-inner,
  .image-gallery-thumbnails-wrapper .image-gallery-thumbnail-inner img {
    height: auto !important;
    width: 100% !important;
  }
}

.thumbnails-prev-btn,
.thumbnails-next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  left: 52px;
  z-index: 20;
}

@media (max-width: 1280px) {
  .thumbnails-prev-btn,
  .thumbnails-next-btn {
    left: 41px;
  }
}

@media (max-width: 1023px) {
  .thumbnails-prev-btn,
  .thumbnails-next-btn {
    left: 52px;
  }
}

.thumbnails-prev-btn > svg,
.thumbnails-next-btn > svg {
  display: block;
  height: 26px;
  width: auto;
}

.thumbnails-prev-btn > svg > path,
.thumbnails-next-btn > svg > path {
  transition: fill 0.15s ease-in-out;
}

@media (max-width: 1280px) {
  .thumbnails-prev-btn > svg,
  .thumbnails-next-btn > svg {
    height: 18px;
  }
}

.thumbnails-prev-btn:hover > svg > path,
.thumbnails-next-btn:hover > svg > path {
  fill: #005a5a;
}

.thumbnails-prev-btn {
  top: 0;
  transform: translate(-50%, calc(-100% - 6px));
}

.thumbnails-prev-btn > svg {
  transform: rotate(-90deg);
}

.thumbnails-next-btn {
  bottom: 0;
  transform: translate(-50%, calc(100% + 6px));
}

.thumbnails-next-btn > svg {
  transform: rotate(90deg);
}

.image-gallery-thumbnails-wrapper {
  margin: 0 !important;
  width: 100% !important;
  max-height: 100% !important;
  height: 100% !important;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnails {
  height: 100% !important;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail {
  width: 100% !important;
  height: auto !important;
  overflow: hidden;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail:not(:first-of-type) {
  /* margin-top: 8px!important */
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail:not(:first-child) {
  margin-top: 24px;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail.active,
.image-gallery-thumbnails-wrapper .image-gallery-thumbnail:focus {
  outline: none;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail,
.image-gallery-thumbnails-wrapper .image-gallery-thumbnail-inner,
.image-gallery-thumbnails-wrapper .image-gallery-thumbnail-inner img {
  /* height: auto!important; */
  width: 100% !important;
}

.image-gallery-thumbnails-wrapper .image-gallery-thumbnail-image {
  width: 100%;
  height: auto;
}

.image-gallery-slide-wrapper {
  width: 100% !important;
}
